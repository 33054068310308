import { Navigate } from 'react-router-dom';

const Root = () => {
    const getRootUrl = () => {
        const user = JSON.parse(localStorage.getItem('shield_user'));

        let url: string = 'dashboard';

        return url;
    };

    const url = getRootUrl();

    return (
        <>{!localStorage.getItem('shield_user') ? <Navigate to={`/auth/login`} /> : <Navigate to={`/${url}`} />}</>
    );
};

export default Root;
