import { useEffect } from 'react';

import AOS from 'aos';

// routes
import Routes from './routes/Routes';

// Themes
import './assets/scss/theme.scss';

const App = () => {
    console.log('%c HOLDING version ', 'background: #203354; color: #fff', process.env.REACT_APP_HOLDING_VERSION);

    useEffect(() => {
        AOS.init();
    }, []);
    return <Routes />;
};

export default App;
