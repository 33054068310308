import React, { Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// components
import Root from './Root';
import PrivateRoute from './PrivateRoute';
import GlobalRoutes from './GlobalRoute';

// lazy load all the views
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const SignUp = React.lazy(() => import('../pages/auth/SignUp'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

// home
const Dashboard = React.lazy(() => import('../pages/Dashboard'));

// Analytics
const ReviewInti = React.lazy(() => import('../pages/Analytics/Inti'));
const ReviewPPL = React.lazy(() => import('../pages/Analytics/PPL'));
const ReviewRHPP = React.lazy(() => import('../pages/Analytics/RHPP'));
const ReviewRHPPRating = React.lazy(() => import('../pages/Analytics/RHPP/Rating'));

// penjualan
const MonitoringSaldo = React.lazy(() => import('../pages/Penjualan/MonitoringSaldo'));
const StokAyam = React.lazy(() => import('../pages/Penjualan/StokAyam'));
const PenjualanGabungan = React.lazy(() => import('../pages/Penjualan/PenjualanGabungan'));
const PenjualanRealisasi = React.lazy(() => import('../pages/Penjualan/PenjualanRealisasi'));
const PenjualanRealisasiMarketing = React.lazy(() => import('../pages/Penjualan/PenjualanRealisasiMarketing'));
const stokAyamRphu = React.lazy(() => import('../pages/Penjualan/StokAyamRphu'));

const RekonsilPiutangBakul = React.lazy(() => import('../pages/Penjualan/RekonsilPiutangBakul'));
const RekapitulasiPembayaranBakul = React.lazy(() => import('../pages/Penjualan/RekapitulasiPembayaranBakul'));
const PinaltyBakulOverTerm = React.lazy(() => import('../pages/Penjualan/PinaltyBakulOverTerm'));
const PrintKeterangan = React.lazy(() => import('../pages/Penjualan/StokAyam/Print/print'));
const RekapHistoryPengajuanLimit = React.lazy(() => import('../pages/Penjualan/RekapHistoryPengajuanLimit'));

//produksi
const EstimasiRhpp = React.lazy(() => import('../pages/Produksi/EstimasiRHPP'));

//radar
const Radar = React.lazy(() => import('../pages/Radar'));
const SensorIoT = React.lazy(() => import('../pages/SensorIoT'));
const SensorIoTDetail = React.lazy(() => import('../pages/SensorIoT/detail'));
const DataPerJam = React.lazy(() => import('../pages/DataPerJam'));

// Universal Master
const KonsolidasiBakul = React.lazy(() => import('../pages/Master/KonsolidasiBakul'));
const KonsolidasiBakulDetail = React.lazy(() => import('../pages/Master/KonsolidasiBakul/Detail'));
const KonsolidasiBakulMerge = React.lazy(() => import('../pages/Master/KonsolidasiBakul/Merge'));
const BatasHariOperasional = React.lazy(() => import('../pages/Master/BatasHariOperasional'));
const TechnicalSupport = React.lazy(() => import('../pages/Master/TechnicalSupport'));
const TechnicalSupportEdit = React.lazy(() => import('../pages/Master/TechnicalSupport/Edit'));
const KepalaProduksi = React.lazy(() => import('../pages/Master/KepalaProduksi'));
const KepalaProduksiEdit = React.lazy(() => import('../pages/Master/KepalaProduksi/Edit'));

// User Role
const Role = React.lazy(() => import('../pages/User/Role'));
const TambahRole = React.lazy(() => import('../pages/User/Role/Tambah'));
const EditRole = React.lazy(() => import('../pages/User/Role/Edit'));

const ResetPassword = React.lazy(() => import('../pages/User/ResetPassword'));

// Map
const MappingFlok = React.lazy(() => import('../pages/Mapping/Flok'));
const MappingFlokTechnicalSupport = React.lazy(() => import('../pages/Mapping/Flok/TechnicalSupport'));
const PlasmaProfileList = React.lazy(() => import('../pages/Plasma/Profile/List'));
const PlasmaProfileDetail = React.lazy(() => import('../pages/Plasma/Profile/Detail'));
const PlasmaProfileEdit = React.lazy(() => import('../pages/Plasma/Profile/Edit'));

const loading = () => (
    <div className="div-loading">
        <div className="loading-container">
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
        </div>
    </div>
);

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            // root route
            path: '/',
            element: <Root />,
        },
        {
            // public routes
            path: '/',
            element: <GlobalRoutes />,
            children: [
                {
                    path: 'auth',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'signup', element: <LoadComponent component={SignUp} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                    ],
                },
            ],
        },
        {
            // protected routes
            path: '/',
            element: localStorage.getItem('shield_user') ? (
                <PrivateRoute status={true} />
            ) : (
                <Navigate to="/auth/login" />
            ),
            children: [
                { path: 'dashboard', element: <LoadComponent component={Dashboard} /> },
                {
                    path: 'penjualan',
                    children: [
                        { path: 'monitoring-saldo', element: <LoadComponent component={MonitoringSaldo} /> },
                        { path: 'stok-ayam', element: <LoadComponent component={StokAyam} /> },
                        { path: 'stok-ayam/print/:id', element: <LoadComponent component={PrintKeterangan} /> },
                        { path: 'penjualan-gabungan', element: <LoadComponent component={PenjualanGabungan} /> },
                        { path: 'penjualan-realisasi', element: <LoadComponent component={PenjualanRealisasi} /> },
                        {
                            path: 'penjualan-realisasi-marketing',
                            element: <LoadComponent component={PenjualanRealisasiMarketing} />,
                        },
                        {
                            path: 'stok-ayam-rphu',
                            element: <LoadComponent component={stokAyamRphu} />,
                        },
                        { path: 'rekonsil-piutang-bakul', element: <LoadComponent component={RekonsilPiutangBakul} /> },
                        {
                            path: 'rekapitulasi-pembayaran-bakul',
                            element: <LoadComponent component={RekapitulasiPembayaranBakul} />,
                        },
                        {
                            path: 'pinalty-bakul-over-term',
                            element: <LoadComponent component={PinaltyBakulOverTerm} />,
                        },
                        {
                            path: 'rekap-history-pengajuan-limit',
                            element: <LoadComponent component={RekapHistoryPengajuanLimit} />,
                        },
                    ],
                },
                {
                    path: 'produksi',
                    children: [{ path: 'estimasi-rhpp', element: <LoadComponent component={EstimasiRhpp} /> }],
                },
                {
                    path: 'analytics',
                    children: [
                        { path: 'inti', element: <LoadComponent component={ReviewInti} /> },
                        { path: 'ppl', element: <LoadComponent component={ReviewPPL} /> },
                        { path: 'rhpp', element: <LoadComponent component={ReviewRHPP} /> },
                        { path: 'rhpp/rating', element: <LoadComponent component={ReviewRHPPRating}></LoadComponent> },
                    ],
                },
                { path: 'atur-pengguna', element: <LoadComponent component={Radar} /> },
                { path: 'realtime-data', element: <LoadComponent component={SensorIoT} /> },
                { path: 'realtime-data/detail/:id', element: <LoadComponent component={SensorIoTDetail} /> },
                { path: 'realtime-data/data-per-jam', element: <LoadComponent component={DataPerJam} /> },
                {
                    path: 'master',
                    children: [
                        { path: 'batas-hari-operasional', element: <LoadComponent component={BatasHariOperasional} /> },
                        { path: 'konsolidasi-bakul', element: <LoadComponent component={KonsolidasiBakul} /> },
                        {
                            path: 'konsolidasi-bakul/detail/:id',
                            element: <LoadComponent component={KonsolidasiBakulDetail} />,
                        },
                        {
                            path: 'konsolidasi-bakul/merge',
                            element: <LoadComponent component={KonsolidasiBakulMerge} />,
                        },
                        {
                            path: 'technical-support',
                            element: <LoadComponent component={TechnicalSupport}></LoadComponent>,
                        },
                        {
                            path: 'technical-support/edit/:perusahaanId/:pplId',
                            element: <LoadComponent component={TechnicalSupportEdit}></LoadComponent>,
                        },
                        {
                            path: 'kepala-produksi',
                            element: <LoadComponent component={KepalaProduksi}></LoadComponent>,
                        },
                        {
                            path: 'kepala-produksi/edit/:perusahaanId/:koordinatorId',
                            element: <LoadComponent component={KepalaProduksiEdit}></LoadComponent>,
                        },
                    ],
                },
                {
                    path: 'user',
                    children: [
                        { path: 'role', element: <LoadComponent component={Role} /> },
                        { path: 'role/tambah', element: <LoadComponent component={TambahRole} /> },
                        { path: 'role/edit/:id', element: <LoadComponent component={EditRole} /> },
                        {
                            path: 'ubah-password',
                            element: <LoadComponent component={ResetPassword}></LoadComponent>,
                        },
                    ],
                },
                {
                    path: 'mapping',
                    children: [
                        {
                            path: 'flok',
                            element: <LoadComponent component={MappingFlok} />,
                        },
                        {
                            path: 'flok/technical-support/',
                            element: <LoadComponent component={MappingFlokTechnicalSupport} />,
                        },
                    ],
                },
                {
                    path: 'plasma',
                    children: [
                        {
                            path: 'profile',
                            element: <LoadComponent component={PlasmaProfileList}></LoadComponent>,
                        },
                        {
                            path: 'profile/detail/:perusahaanId/:peternakId',
                            element: <LoadComponent component={PlasmaProfileDetail}></LoadComponent>,
                        },
                        // {
                        //     path: 'profile/edit',
                        //     element: <LoadComponent component={PlasmaProfileEdit}></LoadComponent>,
                        // },
                    ],
                },
            ],
        },
    ]);
};

export default AllRoutes;
