import { APICore } from './apiCore';

const api = new APICore();

// account
function login(params: { username: string; password: string; kode_perusahaan?: string }) {
    const baseUrl = '/api/v1/auth/user/login';
    return api.create(`${baseUrl}`, params);
}

function logout() {
    const baseUrl = '/api/v1/holding/user/logout';
    return api.create(`${baseUrl}`, {});
}

function signup(params: {
    name: string;
    username: string;
    email: string;
    password: string;
    password_confirmation: string;
}) {
    const baseUrl = '/api/v1/holding/user/register';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
    const baseUrl = '/api/v1/holding/forget-password';
    return api.create(`${baseUrl}`, params);
}

function changePassword(id, params: { password: string }) {
    const baseUrl = '/api/v1/auth/user/change-password';
    return api.update(`${baseUrl}/${id}`, params);
}

export { login, logout, signup, forgotPassword, changePassword };
