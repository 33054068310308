import { useState } from 'react';

// helpers
import { signup as signupApi } from '../../helpers';
import { APICore, setAuthorization } from '../../helpers/api/apiCore';

export default function useSignup() {
    const api = new APICore();

    const [user, setUser] = useState();
    const [error, setError] = useState<any>();

    const signup = ({
        name,
        username,
        email,
        password,
        password_confirmation,
    }: {
        name: string;
        username: string;
        email: string;
        password: string;
        password_confirmation: string;
    }) => {
        const response = signupApi({
            name,
            username,
            email,
            password,
            password_confirmation,
        });
        response
            .then((response) => {
                setUser(response.data);
                api.setLoggedInUser(response.data);
                setAuthorization(response.data!['token']);
            })
            .catch((e) => {
                setError(e);
            });
    };

    return [user, error, signup];
}
