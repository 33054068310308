// helpers
import { useState } from 'react';
import { login as loginApi } from '../../helpers';
import { APICore, setAuthorization } from '../../helpers/api/apiCore';

export default function useLogin() {
    const api = new APICore();

    const [user, setUser] = useState();
    const [error, setError] = useState<any>();

    const login = ({
        username,
        password,
        kode_perusahaan,
    }: {
        username: string;
        password: string;
        kode_perusahaan: string;
    }) => {
        const response = loginApi({ username, password, kode_perusahaan });
        response
            .then((response) => {
                setUser(response.data.data);
                api.setLoggedInUser(response.data.data);
                /* localStorage.setItem('usr', username);
                localStorage.setItem('client_group', response?.data?.client_group);
                console.log(response); */
                setAuthorization(response.data!.data!['token']);
            })
            .catch((e: any) => {
                setError(e);
            });
    };

    return [user, error, login];
}
